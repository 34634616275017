import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div className="row">
      <div className="col-12 col-md-6 offset-md-3">
        <div className="card">
          <div className="card-header">Login</div>
          <div className="card-body">
            <div className="row">
              <input placeholder="email"/>
            </div>
            <div className="row">
              <input placeholder="password"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
